function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}
export default {
  isString: function (obj) {
    return Object.prototype.toString.call(obj) === "[object String]";
  },
  setCookie: function (c_name,value,day,domain){
    var exdate=new Date();
    exdate.setTime(exdate.getTime()+day*24*60*60*1000);
    document.cookie=c_name+ "=" +escape(value)+((day==null) ? "" : ";expires="+exdate.toGMTString())+";path=/;domain="+domain;
  },
  getCookie: function (cname){
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++)
    {
      var c = ca[i].trim();
      if (c.indexOf(name)==0) return c.substring(name.length,c.length);
    }
    return "";
  },
  isFunction: function (obj) {
    return Object.prototype.toString.call(obj) === "[object Function]";
  },
  formatNum: function (strNum) {
    if(strNum<1000) {
      return strNum;
    }
    if(!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
      return strNum;
    }
    var a = RegExp.$1,
    b = RegExp.$2,
    c = RegExp.$3;
    var re = new RegExp();
    re.compile("(\\d)(\\d{3})(,|$)");
    while(re.test(b)) {
      b = b.replace(re, "$1,$2$3");
    }
    return a + "" + b + "" + c;
  },

  formatDate: function (date, fmt) {
    // 1、获取年份
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }

    // 2、获取天数
    let o = {
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "h+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds()
    };

    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + "";
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
      }
    }

    return fmt;
  },
  getDaysBetween:function (dateString1,dateString2){
    var  startDate = Date.parse(dateString1);
    var  endDate = Date.parse(dateString2);
    if (startDate>endDate){
        return 0;
    }
    if (startDate==endDate){
        return 1;
    }
    var days=(endDate - startDate)/(1*24*60*60*1000);
    return  days;
  },
  formatMoney:function (num) {
    // num = num.toString().replace(/\,/g,'');
    if(num){
      var sign = (num == (num = Math.abs(num)));
      num = Math.floor(num*100+0.50000000001);
      var cents=Math.abs(num%100),c;
      if(cents==0){
        c=""
      }else if(cents<10){
        c=".0"+cents
      }else{
        c="."+cents
      }
      num = Math.floor(num/100).toString();
      for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++){
        num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
      }
      return (((sign)?"":'-') + num + c);
    }else{
      return 0;
    }
  },
  checkMobile:function () {
    const sUserAgent = navigator.userAgent;
    const mobileAgents = [
      "Android",
      "iPhone",
      "Symbian",
      "WindowsPhone",
      "iPod",
      "BlackBerry",
      "Windows CE",
    ];
    let isMoblie = false;
    for (let i = 0; i < mobileAgents.length; i++) {
      if (sUserAgent.indexOf(mobileAgents[i]) > -1) {
        isMoblie = true;
        break;
      }
    }
    return isMoblie;
  },
  checkWindowsize:function () {
    var _html = document.getElementsByTagName("html")[0];
    var w = document.documentElement.clientWidth;
    var smscreen;
    if(w<576){
      smscreen = true;
    }else{
      smscreen = false;
    }
    return smscreen;
  }
}
