<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  components: {

  },
  mounted(){
    let that = this;
    // that.fontSize();
    // window.addEventListener('resize', () => {
    //   var _html = document.getElementsByTagName("html")[0];
    //   var w = document.documentElement.clientWidth;
    // 	var newin;
    // 	if(w>1920){
    // 		newin=w
    // 	}else if(w>=1500 && w<1920){
    // 		newin=1920;
    // 	}else if(w>=1200 && w<1500){
    // 		newin=1800;
    // 	}else if(w>=750 && w<1200){
    // 		newin=1680
    // 	}else if(w<750){
    // 		newin=1700
    // 	}
    // 	_html.style.fontSize = newin/120 +"px";
    // })
  },
  methods:{
    // fontSize(){
    // 	var _html = document.getElementsByTagName("html")[0];
    //   var w = document.documentElement.clientWidth;
    // 	var newin;
    // 	if(w>1920){
    // 		newin=w
    // 	}else if(w>=1500 && w<1920){
    // 		newin=1920;
    // 	}else if(w>=1200 && w<1500){
    // 		newin=1800;
    // 	}else if(w>=750 && w<1200){
    // 		newin=1680
    // 	}else if(w<750){
    // 		newin=1680
    // 	}
    // 	_html.style.fontSize = newin/120 +"px";
    // }
  }
}
</script>


<style lang="less">
    @import './style/variable.less';
</style>
