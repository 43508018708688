import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
import zhCN from "vant/lib/locale/lang/zh-CN";
import jaJP from 'vant/es/locale/lang/ja-JP';
import koKR from 'vant/es/locale/lang/ko-KR';
export function Locals(lang) {
  switch (lang) {
    case "CH":
      Locale.use("ch", zhCN);
      break;
    case "EN":
      Locale.use("en", enUS);
      break;
    case "KO":
      Locale.use("ko", koKR);
      break;
    case "JP":
      Locale.use("jp", jaJP);
      break;
  }
}
