export default {
  "ja":{
    "startsWith": "始まる",
    "contains": "含む",
    "notContains": "含まない",
    "endsWith": "終わる",
    "equals": "等しい",
    "notEquals": "等しくない",
    "noFilter": "フィルターなし",
    "filter": "フィルター",
    "lt": "未満",
    "lte": "以下",
    "gt": "超える",
    "gte": "以上",
    "dateIs": "等しい",
    "dateIsNot": "等しくない",
    "dateBefore": "指定日より過去",
    "dateAfter": "指定日より未来",
    "custom": "カスタム",
    "clear": "クリア",
    "apply": "適用",
    "matchAll": "全て一致",
    "matchAny": "いずれかが一致",
    "addRule": "条件追加",
    "removeRule": "条件削除",
    "accept": "はい",
    "reject": "いいえ",
    "choose": "選択",
    "upload": "アップロード",
    "cancel": "キャンセル",
    "completed": "完了済",
    "pending": "保留",
    "dayNames": ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
    "dayNamesShort": ["日", "月", "火", "水", "木", "金", "土"],
    "dayNamesMin": ["日", "月", "火", "水", "木", "金", "土"],
    "monthNames": ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    "monthNamesShort": ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    "chooseYear": "年を選択",
    "chooseMonth": "月を選択",
    "chooseDate": "日を選択",
    "prevDecade": "前の10年",
    "nextDecade": "後の10年",
    "prevYear": "前年",
    "nextYear": "翌年",
    "prevMonth": "先月",
    "nextMonth": "翌月",
    "prevHour": "前の時間",
    "nextHour": "次の時間",
    "prevMinute": "前の分",
    "nextMinute": "次の分",
    "prevSecond": "前の秒",
    "nextSecond": "次の秒",
    "am": "午前",
    "pm": "午後",
    "today": "今日",
    "weekHeader": "週",
    "firstDayOfWeek": 0,
    "dateFormat": "yy-mm-dd",
    "weak": "弱い",
    "medium": "普通",
    "strong": "強い",
    "passwordPrompt": "パスワードを入力",
    "emptyFilterMessage": "オプションなし",
    "searchMessage": "{0} 件の結果",
    "selectionMessage": "{0} 件選択済み",
    "emptySelectionMessage": "選択なし",
    "emptySearchMessage": "該当なし",
    "emptyMessage": "結果なし",
    "aria": {
      "trueLabel": "True",
      "falseLabel": "False",
      "nullLabel": "未選択",
      "star": "1件のスター",
      "stars": "{star}件のスター",
      "selectAll": "全て選択",
      "unselectAll": "すべての選択を解除",
      "close": "閉じる",
      "previous": "前",
      "next": "次",
      "navigation": "ナビゲーション",
      "scrollTop": "トップへスクロール",
      "moveTop": "トップへ移動",
      "moveUp": "上へ",
      "moveDown": "下へ",
      "moveBottom": "一番下へ",
      "moveToTarget": "ターゲットへ移動",
      "moveToSource": "ソースへ移動",
      "moveAllToTarget": "ターゲットへ全て移動",
      "moveAllToSource": "ソースへ全て移動",
      "pageLabel": "{page}ページ",
      "firstPageLabel": "最初のページ",
      "lastPageLabel": "最後のページ",
      "nextPageLabel": "次のページ",
      "previousPageLabel": "前のページ",
      "rowsPerPageLabel": "行/ページ",
      "jumpToPageDropdownLabel": "ページドロップダウンへ",
      "jumpToPageInputLabel": "ページ入力へ",
      "selectRow": "選択済み行",
      "unselectRow": "未選択行",
      "expandRow": "展開済行",
      "collapseRow": "折りたたみ行",
      "showFilterMenu": "フィルターメニューを表示",
      "hideFilterMenu": "フィルターメニューを非表示",
      "filterOperator": "フィルター操作",
      "filterConstraint": "フィルター成約",
      "editRow": "行編集",
      "saveEdit": "保存",
      "cancelEdit": "キャンセル",
      "listView": "リストビュー",
      "gridView": "グリッドビュー",
      "slide": "スライド",
      "slideNumber": "{slideNumber}",
      "zoomImage": "画像を拡大",
      "zoomIn": "拡大",
      "zoomOut": "縮小",
      "rotateRight": "右に回転",
      "rotateLeft": "左に回転"
    }
    }
}
